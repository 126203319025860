export interface ILinkResources {
  domain: string
  resource: string
  relation?: string
  id?: string
}
export function loadLinkResources({ domain, resource, relation, id }: ILinkResources) {
  const link = document.createElement('link')
  link.rel = relation || 'stylesheet'
  if (id) link.id = id
  link.href = `${domain || process.env.ASSETS_DOMAIN}/${resource}` // Ruta completa al archivo CSS generado
  document.head.appendChild(link)
}

export function loadImportMap({ url }) {
  const script = document.createElement('script')
  script.type = 'systemjs-importmap'
  script.src = url
  document.head.appendChild(script)
}

export const loadResources = resources => {
  resources.forEach(({ domain, resource, id, relation }) => {
    loadLinkResources({ domain, resource, id, relation })
  })
}

export const loadImportMaps = (importMapUrl: string, importMaps: string[]) => {
  importMaps.forEach(map => {
    loadImportMap({ url: `${importMapUrl}/${map}` })
  })
}
