import { registerApplication as registerApplicationSPA, start } from 'single-spa'
import { recordApplication } from './record.application'

export function registerApplication() {
  recordApplication.forEach(config => registerApplicationSPA(config))
}

export function startApplication() {
  start()
}
