const blacklist: string[] = [
  '/admin-hr',
  '/catalog',
  '/search',
  '/creator',
  '/learner',
  '/university',
  '/lms',
  '/login',
  '/logout',
  '/org-chart',
  '/playlist',
  '/profile',
  '/talent/goals',
  '/talent/360',
  '/ulms',
  '/withoutfooter',
  '/surveys',
]

export function hideFooter() {
  const { pathname } = location
  const hide: boolean = !pathname.trim().length || blacklist.some(item => pathname.includes(item))

  const footerElement = document.querySelector('footer')
  if (footerElement) footerElement.style.display = hide ? 'none' : ''
}
