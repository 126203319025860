export function footerTycEventListener() {
  const linkElement = document.querySelector("[id='link-terms']")
  if (linkElement)
    linkElement.addEventListener('click', () => {
      document.dispatchEvent(
        new CustomEvent('openTermsConditionsText', {
          bubbles: true,
          detail: { show: true },
        })
      )
    })
}
