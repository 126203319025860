const domain = process.env.ASSETS_DOMAIN
const origin = window.location.origin

export const resources = [
  { domain, resource: 'fontawesome/css/all.min.css', id: 'icon-library' },
  { domain, resource: 'fonts/ubits-fonts.css', id: 'fonts-library' },
  { domain, resource: 'fonts/ubits-fonts-v2.css', id: 'fonts-library' },
  { domain, resource: 'styles/css/ubits-styles.min.css' },
  { domain, resource: 'styles/css/ubits-styles-v2.min.css' },
  { domain: origin, resource: 'assets/favicon.png', relation: 'icon' },
  { domain: origin, resource: 'assets/manifest.json', relation: 'manifest' },
]
