import {
  internationalization,
  InternationalizationConfig,
  InternationalizationEventEnum,
  InternationalizationLanguageEnum,
  InternationalizationTranslationData,
} from '@ubits/library-internationalization'
import { StoreTemporarily } from '../utils'

const storeTemporarily = new StoreTemporarily()
const languageKeyId: string = 'ubitsLanguage'

export async function internationalizationService() {
  await configInternationalization()
  internationalizationOnSetLanguage()
}

async function configInternationalization() {
  const options: InternationalizationConfig = {
    language: getLanguage(),
    resourceCallback,
  }

  await internationalization.config(options)
}

function internationalizationOnSetLanguage() {
  document.addEventListener(InternationalizationEventEnum.SetLanguage, (event: CustomEvent) => {
    const language: InternationalizationLanguageEnum = event.detail
    setLanguage(language)
    window.location.reload()
  })
}

function getLanguage(): InternationalizationLanguageEnum {
  let language = localStorage.getItem(languageKeyId) as InternationalizationLanguageEnum

  if (!language) {
    language = InternationalizationLanguageEnum.SpanishColombia
    setLanguage(language)
  }

  return language
}

function setLanguage(language: InternationalizationLanguageEnum) {
  localStorage.setItem(languageKeyId, language)
}

async function resourceCallback(
  language: InternationalizationLanguageEnum,
  resource: string
): Promise<InternationalizationTranslationData> {
  const path: string = process.env['ASSETS_DOMAIN']

  const url = `${path}/i18n/${resource}/${language}.json`

  const httpRequest = async () => {
    try {
      const respuesta = await fetch(url)

      if (!respuesta.ok) {
        console.error('Error, resource does not exist')
        return null
      }

      const recursos = await respuesta.json()
      return recursos
    } catch (error) {
      console.error('Error:', error)
      return null
    }
  }

  return storeTemporarily.load(url, httpRequest)
}
