import { RegisterApplicationConfig } from 'single-spa'

export const recordApplication: RegisterApplicationConfig[] = [
  {
    name: '@ubits-mf/manager',
    app: () => System.import('@ubits-mf/manager'),
    activeWhen: [''],
    customProps: {
      localModules: process.env.ISLOCAL && [],
    },
  },
]
